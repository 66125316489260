/**
 * @namespace maxkir
 */
if (!window.maxkir) {
  window.maxkir = {};
}

maxkir.FF = navigator.userAgent.indexOf("Firefox") > -1;
maxkir.EDGE = navigator.userAgent.indexOf("Edg") > -1;
maxkir.IE = !!document.documentMode;
maxkir.WebKit = /WebKit/i.test(navigator.userAgent);
maxkir.iPadNew = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
maxkir.iPad = /iPad|iPhone|iPod/.test(navigator.platform) || maxkir.iPadNew;

maxkir.Safari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                navigator.userAgent && !navigator.userAgent.match('CriOS'); 
maxkir.Android = /\bAndroid\b/.test(navigator.userAgent);
maxkir.isMobile = maxkir.iPad || maxkir.Android;
maxkir.isWidget = window.innerWidth <= 600;
maxkir.MAC = /Macintosh/i.test(navigator.userAgent);

maxkir.CHROME = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== "undefined";
  const isIOSChrome = winNav.userAgent.match("CriOS");

  if (isIOSChrome) {
    return false
  }

  return isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    maxkir.EDGE === false;
}

maxkir.is_testing = function() {
  return typeof QUnit === 'object';
};
maxkir.is_dev = function() {
  return location.href.includes('localhost:') || maxkir.is_testing();
}

maxkir.set_title = function(txt) {
  document.title = txt + " - Checkvist";
};

// This function works as getDocumentById
//maxkir.$ = $;
//maxkir.hasClassName = Element.hasClassName;
//maxkir.addClassName = Element.addClassName;
//maxkir.removeClassName = Element.removeClassName;

/**
 * Makes sense only when there is some superclass.
 * 
 * Usage:
 *   superclass = function( ddd ){};
 *   subclass   = function(){ superclass.call(this, ddd_value) }
 *   maxkir.extend(subclass, { foo: false }, superclass );
 *
 * */
maxkir.extend = function(subc, methods, superclass) {
  if (superclass) {
    var F = function() {
    };
    F.prototype = superclass.prototype;
    subc.prototype = new F();
    subc.prototype.constructor = subc;
  }
  
  for (var d in methods) {
    subc.prototype[d] = methods[d];
  }
};

/*------------------------ HELPERS START -----------------------------*/
if (!String.prototype.trim) {
  String.prototype.trim = function() {
    return this.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
  }
}
if (!Array.prototype.remove) {
  Array.prototype.remove = function(element) {
    for (var i = 0; i < this.length; i ++) {
      if (this[i] == element) {
        this.splice(i, 1);
        i --;
      }
    }
  };
}

maxkir.capitalize = function (s) {
  return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();
}

maxkir.uuid = function() {
  return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, function(c) {
    return (Math.random()*16|0).toString(16);
  });
};

maxkir.firstDown = function(txt) {
  if (!txt || txt.length === 0) return txt;
  return txt.substr(0, 1).toLocaleLowerCase() + txt.substr(1);
};

/**
 * @param elem
 * @return {Boolean} true if the object takes not-empty space on the screen
 */
maxkir.visible = function(elem) {
  const el = maxkir.$(elem);
  return el && (el.offsetWidth > 0 && el.offsetHeight > 0)
};

maxkir.onDom = function(fireContentLoadedEvent) {
  if (maxkir._has_dom) {
    fireContentLoadedEvent();
  }
  else {
    window.addEventListener('DOMContentLoaded', fireContentLoadedEvent, false);
  }
};
maxkir.onDom(function() {
  maxkir._has_dom = true;
});

/**
 * Return true if any part of the element is in viewport
 * @param el element
 * @return {boolean} see above
 */
maxkir.inViewport = function(el) {

  var r, html;
  if ( !el || 1 !== el.nodeType ) { return false; }
  html = document.documentElement;
  r = el.getBoundingClientRect();

  return ( !!r
      && r.bottom >= 0
      && r.right >= 0
      && r.top <= html.clientHeight
      && r.left <= html.clientWidth
  );
};

/**
 * @param el
 * @return {*[]} element position on the page
 */
maxkir.getPageOffset = function(el) {
  el = maxkir.$(el);

  var box = el.getBoundingClientRect();
  var x = box.left + window.pageXOffset;
  var y = box.top + window.pageYOffset;

  var res = [x, y];
  res.x = res.left = x;
  res.y = res.top = y;

  //maxkir.info("Position of " + el.id + " is " + res);
  return res;
};

maxkir.save_scroll_position = function () {
  var res = [window.pageXOffset, window.pageYOffset];
  res.restore = function () {
    window.scrollTo(this[0], this[1]);
  };
  return res;

};


maxkir.elementText = function(element) {
  if (element && element.firstChild) {
    return element.firstChild.nodeValue;
  }
  return "";
};

maxkir.selectionText = function() {
  var txt = '';
  if (window.getSelection) {
    txt = window.getSelection();
  }
  else if (document.getSelection) {
    txt = document.getSelection();
  }
  else if (document.selection) {
    txt = document.selection.createRange().text;
  }
  txt = '' + txt; // to convert to String

  if (!txt && maxkir.is_focused_textfield()) {
    var el = document.activeElement;
    if (el && el.value && el.selectionEnd > el.selectionStart) {
      txt = el.value.substring(el.selectionStart, el.selectionEnd);
    }
  }
  return txt;
};

/**
 * @param x {number} viewPort x (clientX)
 * @param y {number} viewPort y (clientY)
 * @returns {Range}
 */
maxkir.create_range_from_coordinates = function(x, y) {
    var range;

    // Try the standards-based way next
    if (document.caretPositionFromPoint) {
        var pos = document.caretPositionFromPoint(x, y);
        range = document.createRange();
        range.setStart(pos.offsetNode, pos.offset);
        range.collapse(true);
    }
    // Next, the WebKit way
    else if (document.caretRangeFromPoint) {
        range = document.caretRangeFromPoint(x, y);
    }

    return range;
};

/**
 * Select the given range
 */
maxkir.select_range = function(range) {
    if (range) {
        if (typeof range.select !== "undefined") {
            range.select();
        } else if (typeof window.getSelection !== "undefined") {
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }
};

maxkir.strip_host = function(url) {
  return url.replace(/^https?:\/\/[^/]+/, '')
}

/**
 * @param count {number}
 * @param word {string}
 * @returns {string}
 */
maxkir.plural = function(count, word) {
  return "" + count + "&nbsp;" + word + (count === 1 ? '' : "s");
};

/**
 * @param {Element} element
 * @return {[number, number]} [selectionStart, selectionEnd]
 */
maxkir.get_selection_range = function(element) {
  try {
    if ((typeof element.selectionStart == 'undefined') && document.selection) {
      // The current selection
      var range = document.selection.createRange();
      // We'll use this as a 'dummy'
      var stored_range = range.duplicate();
      // Select all text
      if (element.type == 'text') {
        stored_range.moveStart('character', -element.value.length);
        stored_range.moveEnd('character', element.value.length);
      } else { // textarea
        stored_range.moveToElementText(element);
      }
      // Now move 'dummy' end point to end point of original range
      stored_range.setEndPoint('EndToEnd', range);
      // Now we can calculate start and end points
      var selectionStart = stored_range.text.length - range.text.length;
      var selectionEnd = selectionStart + range.text.length;
      return [selectionStart, selectionEnd];
    }
    return [element.selectionStart, element.selectionEnd];
  } catch(e) {
    return [0, 0];
  }
};

/**
 * @param {Element} input
 * @param {[number, number]} range_object Selection information in format [selectionStart, selectionEnd]
 * @see maxkir.get_selection_range
 */
maxkir.set_selection_range = function (input, range_object) {
  maxkir.set_selection(input, range_object[0], range_object[1]);
};

/**
 * @param {Element} input
 * @param {number} selectionStart
 * @param {number} selectionEnd
 */
maxkir.set_selection = function (input, selectionStart, selectionEnd) {
  if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(selectionStart, selectionEnd);
  }
  else if (input.createTextRange) {
    var range = input.createTextRange();
    range.collapse(true);
    range.moveEnd('character', selectionEnd);
    range.moveStart('character', selectionStart);
    range.select();
  }
  else {
    var range = document.createRange();
    range.setStart(input, selectionStart);
    var endOffset = input.childNodes.length;
    range.setEnd(input, Math.min(selectionEnd, endOffset));

    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

/**
 * In element, replace range specified by 'range' with text 'replacement'
 * @param {Element} input textarea/input
 * @param {[number, number]} range_object Selection information in format [selectionStart, selectionEnd]
 * @param {String} replacement
 * @param {boolean } [supportUndo] Enabled by default,
 */
maxkir.replace_range = function(input, range_object, replacement, supportUndo) {
  var withUndo = typeof supportUndo === 'undefined' ? true : supportUndo;
  if (withUndo) {
    input.focus();
    maxkir.set_selection_range(input, range_object);
    document.execCommand("insertText", false, replacement)
  }
  else {
    var val = input.value;
    input.value = val.substr(0, range_object[0]) + replacement + val.substr(range_object[1]);
  }
};

/**
 * @param {EventTarget} el
 * @returns true if the currently focused item is textfield which accepts text entering
 */
maxkir.is_text_input = function(el) {
  el = el || document.activeElement;
  if (el.contentEditable === "true") return true;
  return el && el.type &&
      (el.type.indexOf('text') >= 0 ||
          el.type === 'email' ||
          el.type === 'password' ||
          el.type === 'url'
      );
};

maxkir.is_escape_or_tilde = function(e) {
  if (e.key === '`') {
    if (!maxkir.iPad) {
      return false;
    }
    if (maxkir.is_text_input(e.target) && (typeof e.target.value === 'undefined' || e.target.value)) {
      return false;
    }
  }
  return true;
}


/**
 * @returns true if the currently focused item is textfield which accepts text entering
 */
maxkir.is_focused_textfield = function(el) {
  return maxkir.is_text_input(el);
};

/**
 * Finds parent scrollable for the given element, or document.body if such element is not found.
 * Parent scrollable should have overflowY auto or scroll.
 *
 * @param {Element} el
 * @returns {Element}
 */
maxkir.scrollable_parent = function(el) {
    if (el === document.body) return el;

    var overflow = window.getComputedStyle(el)['overflow-y'];
    var isOverflow = overflow === 'auto' || overflow === 'scroll';

    if (isOverflow && el.scrollHeight > el.scrollHeight) {
        return el;
    } else {
        return maxkir.scrollable_parent(el.parentNode);
    }
};

/**
 * Scroll to element
 *
 * @param el {String|Element} element to scroll to
 * @param {number} [topMargin] distance between top of the screen and the element top (default = 30)
 * @param {number} [bottomMargin] distance between bottom of the screen and the element top (default = topMargin)
 * @param {number|boolean} [smoothDuration] if number, number of msecs for the smooth scrolling
 * @param parentScrollable {Element} parent element to scroll, will be defined automatically if not set
 */
maxkir.scrollTo2 = function(el, topMargin, bottomMargin, smoothDuration, parentScrollable) {
    el = typeof el === 'string' ? document.getElementById(el) : el;
    if (!el) return;
    if (typeof topMargin === 'undefined') topMargin = 30;
    if (!bottomMargin) bottomMargin = topMargin;

    parentScrollable = parentScrollable || maxkir.scrollable_parent(el);
    var myPos = el.getBoundingClientRect();
    var globalScroll = parentScrollable === document.body;
    var parentScrollableTop = globalScroll ? 0 : parentScrollable.getBoundingClientRect().top;

    var viewHeight = globalScroll ? document.documentElement.clientHeight : parentScrollable.clientHeight;
    // maxkir.debug("global scroll: " + globalScroll + " smooth: " + smoothDuration);

    var scroll = function(scrollDiff) {
        if (smoothDuration) {
            maxkir.smoothScrollBy(scrollDiff, globalScroll ? null : parentScrollable, smoothDuration);
        }
        else {
            globalScroll ?
                window.scrollBy(0, scrollDiff) :
                parentScrollable.scrollTop += scrollDiff;
        }
    };

    if (bottomMargin + topMargin >= viewHeight) {
        bottomMargin = viewHeight - topMargin;
        topMargin = 0;
    }


    var scrollDiff = myPos.top - parentScrollableTop + bottomMargin - viewHeight;

    //maxkir.debug(myPos.top, parentScrollableTop, myPos.top - parentScrollableTop, topMargin, bottomMargin, viewHeight, scrollDiff);

    if (scrollDiff > 0) {
        scroll(scrollDiff);
    }

    scrollDiff = myPos.top - parentScrollableTop - topMargin;
    if (scrollDiff < 0) {
        scroll(scrollDiff);
    }

};

/**
 * Smooth vertical scrolling
 * @param {number} shiftY number of pixels to scroll, > 0 to scroll down
 * @param {Element} [element] Element to scroll, window if not set
 * @param {Number} durationMsec 200 if unset
 */
maxkir.smoothScrollBy = function(shiftY, element, durationMsec) {
    var scrollOffsets = element ?
        {top: element.scrollTop} :
        {top: window.pageYOffset, left: window.pageXOffset};

    maxkir.animate(durationMsec > 10 ? durationMsec : 200, function(x) {
        if (element) {
            element.scrollTop = scrollOffsets.top + Math.round(x * shiftY);
        }
        else {
            scrollTo(scrollOffsets.left, scrollOffsets.top + Math.round(x * shiftY));
        }
    });
};


/**
 * @param element Smooth scroll to make the given element visible on the page, to bring user attention
 */
maxkir.scroll2topVisible = function(element) {
  maxkir.scrollTo2(
    element, 90, document.documentElement.clientHeight * 3 / 4, 200, document.body
  );
}



maxkir.wait_for = function(condition, to_run, wait_secs, on_fail) {
  if (!wait_secs) wait_secs = 3;
  var count = 0;
  var pause = 50;
  var cycles_count = (wait_secs * 1000/ pause);

  (function checker() {
    if (condition()) {
      to_run();
    }
    else {
      if (count++ < cycles_count){
        setTimeout(checker, pause);
      }
      else {
        if (on_fail) on_fail();
      }
    }
  })();
};

/**
 * Usage:
 * <pre>
 *  var delayed = maxkir.delay_action(function() { }, 200);
 *  delayed.start(); // action will be called 200 msec since last start()
 *  delayed.stop(); // action will be discarded
 *  </pre>
 *
 * @param {function} action - action to be called
 * @param {number} delay - msecs of delay after which the action will be called
 */
maxkir.delay_action = function(action, delay) {
    var timeout;
    return {
        start: function() {
            this.stop();
            timeout = setTimeout(action, delay);
        },

        stop: function() {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        }
    }
};

/**
 * Perform animation with requestAnimationFrame
 * @param {number} durationMsec
 * @param {function} callback accepts parameter - progress; from 0 to 1 (1 - for the last frame)
 */
maxkir.animate = function(durationMsec, callback) {
  if (typeof window.requestAnimationFrame !== 'function') {
    callback(1);
    return;
  }

  var start = null;
  window.requestAnimationFrame(function animate(timestamp) {
    if (!start) {
      start = timestamp;
    }
    var progress = timestamp - start;

    callback(Math.min(1. * progress / durationMsec, 1));

    if (progress <= durationMsec) {
      window.requestAnimationFrame(animate);
    }
  });
};

maxkir.WB="[?.,\"`'!;:#$%&()*+-/<>=@[\\]\\^_{}|~\\s]";
maxkir.highlight_text = function(txt, terms, word_boundaries) {
  if (terms.length === 0) return txt;

  var highlight_term = function(txt, term) {
    if (!txt) return txt;
    if (!term.length) return txt;

    var t = term.replace(/\\/g, "\\\\");
    t = t.replace(/([|*.[\]^{}()$+?])/g, "\\$1");
    if (word_boundaries === 'start') {
      t = "(^|" +maxkir.WB+ ")(" + t + ")";
    }
    else if (word_boundaries) {
      t = "(^|" +maxkir.WB+ ")(" + t + ")(?=$|" +maxkir.WB+ ")";
    }
    else {
      t = "()(" + t + ")";
    }
    var re = new RegExp(t, "gi");
    return txt.replace(re, function(match, pre_match, matched, index, s) {
      var lt_idx = s.lastIndexOf("<", index);
      var gt_idx = s.lastIndexOf(">", index);
      if (gt_idx < lt_idx) {
        return match;
      }
      return pre_match + "<span class=\"hlt\">" + matched + "</span>";
    })
  };

  for(var i = terms.length; i-- > 0;) {
    var term = terms[i];
    if (term === '&') {
      term = "&amp;";
    }
    txt = highlight_term(txt, term)
  }
  return txt;
};


var HTML_ESCAPE_TEST_RE = /[&<>"']/;
var HTML_ESCAPE_REPLACE_RE = /[&<>"']/g;
var HTML_REPLACEMENTS = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&apos;'
};
var HTML_ESCAPE_REPLACE_BACK_RE = /(&amp;|&lt;|&gt;|&quot;|&apos;)/g;
var HTML_REPLACEMENTS_BACK = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&apos;': "'"
};

maxkir.escapeHtml = function(str) {

    if (HTML_ESCAPE_TEST_RE.test(str)) {
        var replaceUnsafeChar = function(ch) {
            return HTML_REPLACEMENTS[ch];
        };

        return str.replace(HTML_ESCAPE_REPLACE_RE, replaceUnsafeChar);
    }
    return str;
};

maxkir.unescapeHtml = function(str) {
    return str.replace(HTML_ESCAPE_REPLACE_BACK_RE, function(ch) {
        return HTML_REPLACEMENTS_BACK[ch];
    });
};

/**
 * @param text text to copy to clipboard
 * @return {boolean} if operation was successful
 */
maxkir.copy_text = function(text) {
  var focused = document.activeElement;
  var scrollY = window.pageYOffset;
  var scrollX = window.pageXOffset;
  
  var element = document.createElement("textarea");
  element.style.height='1px';
  element.style.width='1px';
  element.style.border='none';
  document.body.appendChild(element);
  element.value = text;
  element.focus();

  element.contentEditable = true;
  element.readOnly = false;

  var range = document.createRange();
  range.selectNodeContents(element);

  var selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);

  element.setSelectionRange(0, 999999);

  var success = document.execCommand("copy");
  if (focused) {
    focused.focus();
  }
  document.body.removeChild(element);
  window.scrollTo(scrollX, scrollY);
  return success;
};

maxkir.Clipboard = function() {
  var _text,
      _button,
      _cancelCallback,
      _copy = function() {
        return maxkir.copy_text(_text);
      },
      _listener = function(e) {
        if (_copy()) {
          maxkir.debug("Text " + _text + " was copied to clipboard");
        }
        if (_cancelCallback) {
          _cancelCallback();
        }
        e.preventDefault();
        e.stopPropagation();
      };
      
  return {
    setText: function(text) {
      _text = text;
    },
    
    copy: function() {
      return _copy();
    },
    
    glue: function(buttonId) {
      _button = maxkir.$(buttonId); 
      _button.addEventListener('click', _listener);
    },

    addEventListener: function(eventName, callback) {
      if (eventName == 'onComplete') {
        _cancelCallback = callback;
      }
    },

    destroy: function() {
      _button.removeEventListener('click', _listener);
    }
  }
};

/**
 * For a elementID, sets its height = height of its descendant according to cssSelector
 * @param {string} elementId   
 */
maxkir.toggle_height = function(elementId, cssSelector) {
  const el = document.getElementById(elementId);
  if (el) {
    if (!el.offsetHeight) {
      el.style.height = el.querySelector(cssSelector).offsetHeight + 'px';
    }
    else {
      el.style.height = 0;
    }
  }
};

/**
 * 
 * Save cross-session value in persistent browser storage
 * 
 * @param {string} flag 
 * @param {boolean|string} value
 * @param {number} [ttl_secs] time to live after write, in seconds
 */
maxkir.set_flag = function(flag, value, ttl_secs) {
  if (!value || value === 'false' || value === 'null') {
    localStorage.removeItem(flag);
  }
  else {
    var val = {data: value, whenSet: new Date().getTime(), ttl: ttl_secs || null};
    localStorage.setItem(flag, JSON.stringify(val))
  }
};

/**
 * Get value stored via maxkir.set_flag
 * 
 * @param {string} flag
 * @return {boolean|string|null}
 */
maxkir.get_flag = function(flag) {
  var json = localStorage.getItem(flag);
  if (!json) return json;

  try {
    json = JSON.parse(json);

    if (!json.ttl || new Date().getTime() - json.whenSet < json.ttl * 1000) {
      return json.data;
    }
    maxkir.set_flag(flag, null);
    return null;
  }
  catch (e) {
    return json;
  }
};



/*------------------------ HELPERS END -----------------------------*/

/*------------------------ DEBUG issues START -----------------------------*/

maxkir.timer_start = function() {
  var t = new Date();
  maxkir._current_timer = maxkir._current_timer || t;
  return t;
};

maxkir.timer_stop = function(message, optional_timer) {
  if (!optional_timer) {
    optional_timer = maxkir._current_timer;
    maxkir._current_timer = null;
  }
  maxkir.info(message + ": " + (new Date().getTime() - optional_timer.getTime()) + "ms");
};

maxkir.bench = function(message, toRun) {
  var start = maxkir.timer_start();
  toRun();
  maxkir.timer_stop(message, start);
};

maxkir.debug = function() {};
maxkir.info = maxkir.debug;
maxkir.error = window.alert;

if (window.console && window.console.error) {

  maxkir._debug_start_timestamp = 0;
  (function() {
    var timed_args = function() {
        var prefix = '';

        if (maxkir._debug_start_timestamp > 0) {
            var t = "" + (new Date().getTime() - maxkir._debug_start_timestamp);

            var t_big = t.substr(0, 10),
                t_rest = t.substr(10);
            prefix = "" + t_big + "." + t_rest + " ";
        }
        else {
            prefix = new Date().toISOString() + " ";
        }

        var args = [prefix], i;

        for(i = 0; i < arguments.length; i ++) {
            args.push(arguments[i]);
        }
        return args;
    };

    var localLog = [];
    var maxLogSize = 500;

    maxkir.local_log_text = function() {
        var out = "";
        for(var i = 0; i < localLog.length; i ++) {
            var line = localLog[i];
            for(var j = 0; j < line.length; j ++) {
                if (line[j] && line[j].stack) {
                    out += line[j] + "  " + line[j].stack;
                }
                else if (line[j] && typeof line[j] === 'object') {
                    out += JSON.stringify(line[j]) + "  ";
                }
                else if (line[j] && typeof line[j].toString === 'function') {
                    out += line[j].toString() + "  ";
                }
            }
            out += "\n";
        }
        return out;
    };

    var set_log_method = function(method) {

      maxkir[method] = function() {
        var args = timed_args.apply(this, arguments);
        if (method !== 'info') {
            args.splice(1, 0, method.charAt(0).toUpperCase());
        }
        localLog.push(args);
        if (localLog.length > maxLogSize) {
          localLog = localLog.slice(maxLogSize/3);
        }

        if (maxkir._has_console) {
          console[method].apply(console, args);
          return;
        } 
          
        try {
          console[method].apply(console, args);
          maxkir._has_console = true;  
        }
        catch (e) {
          // Case for IE, which doesn't allow 'apply' call
          var what = "";
          for(var i = 0; i < args.length; i ++) {
            what += args[i] + "  ";
          }
          console[method](what);
        }
      };
    };

    set_log_method("error");
    set_log_method("info");
    set_log_method("warn");
    set_log_method("debug");

    var old_debug = maxkir.debug;
    maxkir.debug = function() {
        if (maxkir._debug) {
            old_debug.apply(maxkir, arguments);
        }
    }
    var old_info = maxkir.info;
    maxkir.info = function() {
        if (maxkir._info || maxkir._debug) {
            old_info.apply(maxkir, arguments);
        }
    }

    maxkir._info = maxkir.is_dev();
  })();

}

maxkir.exception = function(e) {
  maxkir.error(e);
};
/*------------------------ DEBUG issues END -----------------------------*/

maxkir.format_size = function(bytes) {
  var i = 0;
  while(bytes > 199) {
    bytes = bytes / 1024;
    i++;
  }

  return Math.max(bytes, 0.1).toFixed(1) + ' ' + ['B','kB', 'MB', 'GB', 'TB', 'PB', 'EB'][i];
};



// Polifill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/bind
(function initBindPolyfill() {
    var bind,
        slice = [].slice,
        proto = Function.prototype;

    if (typeof proto['bind'] !== 'function') {
        // adapted from Mozilla Developer Network example at
        // https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/Function/bind
        bind = function bind(obj) {
            var args = slice.call(arguments, 1),
                self = this,
                nop = function() {},
                bound = function() {
                    return self.apply(this instanceof nop ? this : (obj || {}), args.concat(slice.call(arguments)));
                };
            nop.prototype = this.prototype || {}; // Firefox cries sometimes if prototype is undefined
            bound.prototype = new nop();
            return bound;
        };
        proto.bind = bind;
    }
})();

(function initPolyfills() {
    var hasOwnProperty = Object.prototype.hasOwnProperty;
    if (!Object.keys) {
        Object.keys = function (obj) {
            if (typeof obj !== 'object' && (typeof obj !== 'function' || obj === null)) {
                throw new TypeError('Object.keys called on non-object');
            }

            var result = [], prop;

            for (prop in obj) {
                if (hasOwnProperty.call(obj, prop)) {
                    result.push(prop);
                }
            }

            return result;
        }
    }
    
    if (!Object.values) {
        Object.values = function(obj) {
            if (typeof obj !== 'object' && (typeof obj !== 'function' || obj === null)) {
                throw new TypeError('Object.keys called on non-object');
            }

            var result = [];
            for (var prop in obj) {
                if (hasOwnProperty.call(obj, prop)) {
                    result.push(obj[prop]);
                }
            }
            return result;
        };
    }

    if (!String.prototype.startsWith) {
        Object.defineProperty(String.prototype, 'startsWith', {
            enumerable: false,
            configurable: false,
            writable: false,
            value: function (searchString, position) {
                position = position || 0;
                return this.lastIndexOf(searchString, position) === position;
            }
        });
    }

    if (!String.prototype.endsWith) {
        Object.defineProperty(String.prototype, 'endsWith', {
            value: function (searchString, position) {
                var subjectString = this.toString();
                if (position === undefined || position > subjectString.length) {
                    position = subjectString.length;
                }
                position -= searchString.length;
                var lastIndex = subjectString.indexOf(searchString, position);
                return lastIndex !== -1 && lastIndex === position;
            }
        });
    }
})();
